/** @format */

exports.default = {
  name: { id: 'sports.fb', defaultMessage: 'Football: Full-Time' }, //name
  shortName: { id: 'sports.fb.short', defaultMessage: 'Football' }, //short name (used in mobile modes)
  featuredCompetitions: [
    //some competition ids that we always feature
    '1', // England Premier League
    '301', // FIFA World Cup
    '38', // France Ligue 1
    '12', // Germany Bundesliga
    '19', // Italy Serie A
    '16', // Spain La Liga
    '28', // UEFA Champions League
    '29', // UEFA Europa League
    '32', // UEFA European Championship
  ],
  featuredCompetitionsName: [
    // 'England Premier League',
    // 'FIFA World Cup',
    // 'France Ligue 1',
    // 'Germany Bundesliga',
    // 'Italy Serie A',
    // 'Spain La Liga',
    // 'UEFA Champions League',
    // 'UEFA Europa League',
    // 'UEFA European Championship',
  ], //feature competitions if name contains one of these
  //noDraws: false, //this sport doesn't have draws (i.e. tennis, esports, etc)
  enabled: true, //is visible to normal users
  maxTimeout: 259200, //maximum order expiry time
  correlated: {
    //define what subsports appear wehn clicking the + symbol; they must match in terms of offer group structure
    fb_ht: {
      sport: 'fb_ht',
      name: { id: 'sports.fbht', defaultMessage: 'Football: Half-Time' },
      shortName: { id: 'sports.fbht.short', defaultMessage: 'Football [HT]' },
      correlatedOrder: ['fb_ht', /*'fb_2h',*/ 'fb'], //for the purpose of + symbol on own page
    },
    // technically exists, 18bet bookie only, not in cutils
    fb_2h: {
      sport: 'fb_2h',
      name: { id: 'sports.fb2h', defaultMessage: 'Football: Second Half' },
      shortName: { id: 'sports.fb2h.short', defaultMessage: 'Football [2H]' },
      correlatedOrder: ['fb_2h', 'fb_ht', 'fb'], //for the purpose of + symbol on own page
    },
    fb_corn: {
      sport: 'fb_corn',
      name: { id: 'sports.fbcorn', defaultMessage: 'Football: Corners' },
      shortName: { id: 'sports.fbcorn.short', defaultMessage: 'Corners' },
      correlatedOrder: ['fb_corn', 'fb_corn_ht'], //for the purpose of + symbol on own page
    },
    fb_corn_ht: {
      sport: 'fb_corn_ht',
      name: { id: 'sports.fbcornht', defaultMessage: 'Football: HT Corners' },
      shortName: { id: 'sports.fbcornht.short', defaultMessage: 'Corners [HT]' },
      correlatedOrder: ['fb_corn_ht', 'fb_corn'], //for the purpose of + symbol on own page
    },
    fb_et: {
      sport: 'fb_et',
      name: { id: 'sports.fbet', defaultMessage: 'Football: Extra-Time' },
      shortName: { id: 'sports.fbet.short', defaultMessage: 'Football [ET]' },
      correlatedOrder: ['fb_et', 'fb_ht', 'fb'], //for the purpose of + symbol on own page
    },
    fb_book: {
      sport: 'fb_book',
      name: { id: 'sports.fbbook', defaultMessage: 'Football: Bookings' },
      shortName: { id: 'sports.fbbook.short', defaultMessage: 'Football [BOOK]' },
      correlatedOrder: ['fb_book'], //for the purpose of + symbol on own page
    },
    // technically exists, cmd bookie only
    fb_et_ht: {
      sport: 'fb_et_ht',
      name: { id: 'sports.fbetht', defaultMessage: 'Football: Extra-Time Half-Time' },
      shortName: { id: 'sports.fbetht.short', defaultMessage: 'Football [ET HT]' },
      correlatedOrder: ['fb_et_ht', 'fb_et', 'fb_ht', 'fb'], //for the purpose of + symbol on own page
    },
  },
  correlatedOrder: [
    //for the purpose of + symbol on own page, used in other places as well so should always be defined
    'fb',
    'fb_ht',
    // 'fb_2h',
    'fb_corn',
    'fb_corn_ht',
    'fb_et',
    'fb_book',
    //'fb_et_ht'
  ],
  trustScores: true, //do we display the scores for this sport (also applies to subsports)
  scoresFallbacks: {
    //if we don't have a score (for a sub-sport), can we fall back to something else
    fb_ht: 'fb',
    //fb_et_ht: 'fb_et',
    fb_corn_ht: 'fb_corn',
  },
  useIrTypes: true, //do we use the ir variations when opening the betslip (for,ir,x,y...)
  //this also determines if the offer update function will apply handicap offsets based on score
  noMultiline: false, //this sport doesn't support the multiline feature
  matchDataOpener: 'for,a/null', //what bet type/handicap to use when opening match info window (because of offerhist)
  offerGroupsOrder: [
    //what order do the offer groups appear in (this also defines which ones are available)
    'wdw',
    'ah',
    'ahou',
    'tahou,h',
    'tahou,a',
    'dc',
    'oe',
    'cs',
    'clean,h',
    'clean,a',
    'score,both',
    'score,h',
    'score,a',
    'winToNil,a',
    'winToNil,h',
    'moou',
    'aou',
    'gr',
    'exactTotal',
    'wm',
    //'dnb',
    'moBothScore',
    'othercs',
    // multirunner offer group, will be hacked under main section to not appear at end
    'win',
  ],
  //symphathetic bet types ... it's a 'good enough' interface remap that makes
  //some betypes have fallback columns. the point of this is that events in favorites
  //that have differents sports may now show some non-exact-matching bet types
  //the way to read is: <bet_type_I_lack> : <bet_type_I_have>
  sympBetTypes: {
    ml: 'wdw',
    'timeWin,tp,reg,wdw': 'wdw',
    'timeWin,tp,all,wdw': 'wdw',
    'timeWin,tp,reg,ml': 'wdw',
    'timeWin,tp,all,ml': 'wdw',
    'tennisMatch,all': 'wdw',
    'timeAh,tp,all': 'ah',
    'tennisAh,all,set': 'ah',
    'tennisCs,all,set': 'cs',
    //tennisAhou,all,game': 'ahou',
    'timeAhou,tp,all': 'ahou',
    //'timeWin,tp,all,ml': 'wdw',
    //'timeOe,tp,all,oe': 'oe',
    //'timeOe,tp,reg,oe': 'oe',
    //'tennisOe,all,game': 'oe',
  },
};
