/** @format */

import DSM from '../../lib/DSM';
import Session from '../../lib/Session';
import { hashParams } from '../../lib/nav';
import { lastWeek } from '../../lib/time';

import { toCamelCaseAll, toCamelCase } from '../../lib/camelSnake';
import { toStdDate } from '../../lib/time';

import { fromJS } from 'immutable';

function getThisWeek() {
  let today = new Date();
  let d1 = new Date();
  let d2 = new Date();
  //Monday-ify
  d1.setTime(d1.getTime() - today.getDay() * 24 * 60 * 60 * 1000);

  return {
    from: toStdDate(d1),
    to: toStdDate(d2),
  };
}

function getLastWeek() {
  let today = new Date();
  let d1 = new Date();
  let d2 = new Date();
  //Monday-ify - 7
  d1.setTime(d1.getTime() - (today.getDay() + 6) * 24 * 60 * 60 * 1000);
  d2.setTime(d1.getTime() + 6 * 24 * 60 * 60 * 1000);

  return {
    from: toStdDate(d1),
    to: toStdDate(d2),
  };
}

function getLastXDays(x) {
  let d1 = new Date();
  d1.setTime(d1.getTime() - x * 24 * 60 * 60 * 1000);
  let d2 = new Date();

  return {
    from: toStdDate(d1),
    to: toStdDate(d2),
  };
}

function relativeify(_filters, relative) {
  if (relative === 'allTime') {
    //skip
  } else if (_filters['q']) {
    let splitQ = _filters['q'].split('/');

    for (let idx in splitQ) {
      let atoms = splitQ[idx].split(':');

      if (atoms[0] === 'f' && atoms[1] === 'bookie_date_day') {
        if (atoms[2] === 'ge') {
          atoms[3] = '{from}';
        } else if (atoms[2] === 'le') {
          atoms[3] = '{to}';
        }
      }

      splitQ[idx] = atoms.join(':');
    }

    _filters['q'] = splitQ.join('/');
  }

  return _filters;
}

function derelativeify(query, relative) {
  let _query = { ...query };

  let toFrom = {};
  if (relative === 'lastWeek') {
    toFrom = getLastWeek();
  } else if (relative === 'thisWeek') {
    toFrom = getThisWeek();
  } else if (relative === 'last90Days') {
    toFrom = getLastXDays(90);
  } else {
    return _query;
  }

  for (let param in _query) {
    _query[param] = _query[param].replace('{from}', toFrom.from).replace('{to}', toFrom.to);
  }

  return _query;
}

function generateParams(_filters) {
  let filters = {};

  if (_filters['as'] && !filters['as']) {
    filters['as'] = _filters['as'];
  }

  //put page size in t
  if (_filters['t']) {
    filters['t'] = _filters['t'];
  } else if (_filters['pageSize']) {
    if (_filters['offset']) {
      filters['t'] = _filters['pageSize'] + ':' + _filters['offset'];
      delete _filters['offset'];
    } else {
      filters['t'] = _filters['pageSize'] + ':0';
    }

    delete _filters['pageSize'];
  }

  //put dateFrom and dateTo into q
  if (!_filters['q']) {
    _filters['q'] = '';
  }

  let splitQ = _filters['q'].split('/');
  let _params = [];
  let dateFromFound = false;
  let dateToFound = false;

  for (let idx in splitQ) {
    let atoms = splitQ[idx].split(':');

    if (atoms[0] === 'f' && atoms[1] === 'bookie_date_day') {
      dateFromFound = true;
      if (atoms[2] === 'ge') {
        if (_filters['dateFrom']) {
          atoms[3] = toStdDate(_filters['dateFrom']);
          _filters['dateFrom'] = '';
          _params.push(atoms.join(':'));
        } else if (typeof _filters['dateFrom'] === 'undefined') {
          _params.push(splitQ[idx]);
        }
      } else if (atoms[2] === 'le') {
        if (_filters['dateTo']) {
          atoms[3] = toStdDate(_filters['dateTo']);
          _filters['dateTo'] = '';
          _params.push(atoms.join(':'));
        } else if (typeof _filters['dateTo'] === 'undefined') {
          _params.push(splitQ[idx]);
        }
      } else {
        _params.push(atoms.join(':'));
      }
    } else {
      _params.push(splitQ[idx]);
    }
  }

  //not found, but exists, must have been added
  if (!dateFromFound && _filters['dateFrom']) {
    _params.push('f:bookie_date_day:ge:' + toStdDate(_filters['dateFrom']));
  }

  //not found, but exists, must have been added
  if (!dateToFound && _filters['dateTo']) {
    _params.push('f:bookie_date_day:le:' + toStdDate(_filters['dateTo']));
  }

  //splitQ = _.compact(splitQ)
  filters['q'] = _params.filter((item) => !!item).join('/');

  //ccy code
  if (_filters['ccyCode']) {
    filters['ccyCode'] = _filters['ccyCode'].toUpperCase();
  }

  return filters;
}

//we need to cast some things
function prepareParams(params) {
  //get dateFrom and dateTo from q
  if (params['q']) {
    let splitQ = params['q'].split('/');

    for (let item of splitQ) {
      let atoms = item.split(':');

      if (atoms[0] === 'f' && atoms[1] === 'bookie_date_day') {
        if (atoms[2] === 'ge') {
          params['dateFrom'] = atoms[3];
        } else if (atoms[2] === 'le') {
          params['dateTo'] = atoms[3];
        } else {
          params['dateTo'] = atoms[2];
          params['dateFrom'] = atoms[2];
        }
      }
    }
  }

  //get page size and offset from t
  if (params['t']) {
    let splt = params['t'].split(':');
    params['pageSize'] = parseInt(splt[0], 10);
    params['offset'] = parseInt(splt[1], 10);
    delete params['t'];
  }

  return params;
}

const INITIAL_STATE = {
  //the data in the table
  table: {},
  //the navigation options
  nav: {},
  //dashboard data
  dashboardData: {},
  //dashboard navigation
  dashboardDataNav: {},
  //is loading the data and nav
  isLoading: false,
  //is downloading the data as a file
  isDownloading: false,
  isDownloadFailed: false,
  //was page loaded for the first time
  firstLoad: false,

  //call parameters
  params: toCamelCaseAll(hashParams(), true),

  //saved user views
  views: {},
  //selected user view
  selectedView: '',

  //hack to set window hash to something
  setHashTo: null,
};

let initialState = fromJS(INITIAL_STATE);

const functions = {
  ////// BETS

  //make request based on currently selected parameters
  getBets: (state, action) => {
    let params = toCamelCaseAll(hashParams(), true);
    let paramsToSet = prepareParams(JSON.parse(JSON.stringify(params)));
    let paramsToSend = generateParams(params);

    let root = 'report';
    if (action.data.format) {
      if (action.data.format === 'csv') {
        root = 'export/csv';
        delete paramsToSend['t'];
      } else if (action.data.format === 'xls') {
        root = 'export/xls';
        delete paramsToSend['t'];
      }
    }

    paramsToSend['timezone'] = Session.get('settings.general.timezone');

    DSM.last(
      `/s/histbets/${root}/`,
      {
        method: 'GET',
        message: 'loadBets',
        downloadAs: action.data.format ? `history-bets.${action.data.format}` : '',
        body: paramsToSend,
        extras: {
          format: action.data.format,
        },
      },
      action.data.actions,
      'currentBetsRequest'
    );

    state = state.set('params', fromJS(paramsToSet));
    let views = state.get('views');
    if (views) {
      let viewTo = '';
      views.forEach((view, vId) => {
        if (view.getIn(['query', 'q']) === state.getIn(['params', 'q'])) {
          viewTo = vId;
        }
      });
      state = state.set('selectedView', viewTo);
      if (viewTo) {
        state = state.set('setHashTo', null);
      }
    }

    if (root === 'report') {
      return state.set('isLoading', true);
    } else {
      return state.set('isDownloading', action.data.format);
    }
  },

  //used by the mobile version to download all data
  getAllBets: (state, action) => {
    let root = 'report';
    if (action.data.format) {
      if (action.data.format === 'csv') {
        root = 'export/csv';
      } else if (action.data.format === 'xls') {
        root = 'export/xls';
      }
    }

    DSM.last(
      `/s/histbets/export/csv/?&q=a:customer_ccy/a:event_date_month/a:market_type/a:system/a:competition/hv:customer_turnover/a:event/a:event_date_day/f:bookie_date_day:ge:2000-01-01/`,
      {
        method: 'GET',
        message: 'loadBets',
        downloadAs: action.data.format ? `history-bets.${action.data.format}` : '',
        extras: {
          format: action.data.format,
        },
      },
      action.data.actions,
      'currentBetsRequest'
    );

    if (root === 'report') {
      return state.set('isLoading', true);
    } else {
      return state.set('isDownloading', action.data.format);
    }
  },

  //handle the bet data
  loadBets: (state, action) => {
    if (action.data.status === 'ok') {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', false);
        return state;
      }

      state = state.set('table', fromJS(action.data.data.table));
      state = state.set('nav', fromJS(action.data.data.nav));
    } else {
      //special case for download
      if (action.data.extras && action.data.extras.format) {
        state = state.set('isDownloading', false);
        state = state.set('isDownloadFailed', true);
        return state;
      }
    }

    return state.mergeDeep({
      isLoading: false,
      firstLoad: true,
    });
  },

  //update parameters and set page hash
  updateBetsParam: (state, action) => {
    if (action.data.param === 'username') {
      state = state.setIn(['params', 'as'], action.data.value);
    } else {
      state = state.setIn(['params', action.data.param], action.data.value);
    }

    if (action.data.param === 'pageSize') {
      state = state.setIn(['params', 'offset'], 0);
    }
    let hash = [];

    let params = generateParams(state.get('params').toJS());
    for (let param in params) {
      if (params[param] && param !== 'username') {
        hash.push(param + '=' + params[param]);
      }
    }

    let asParam = state.getIn(['params', 'as']) ? `as=${state.getIn(['params', 'as'])}` : '';

    if (asParam && !hash.some((entry) => entry.startsWith('as='))) {
      hash.unshift(asParam);
    }

    state = state.set('selectedView', '');
    return state.set('setHashTo', hash.length ? `${hash.join('&')}` : '');
  },

  //clear bet parameters and clear hash
  clearBetsParams: (state, action) => {
    state = state.set('selectedView', '');
    return state.set(
      'setHashTo',
      `#q=f:bookie_date_day:ge:${lastWeek()['dateFrom']}/f:bookie_date_day:le:${
        lastWeek()['dateTo']
      }${action.data.ccy ? `&ccyCode=${action.data.ccy}` : ''}&ts=${+new Date()}`
    );
  },

  ////// DASHBOARD DATA

  //make request to get dashboard data
  getDashboardData: (state, action) => {
    let params = toCamelCaseAll(hashParams(), true);
    let paramsToSet = prepareParams(JSON.parse(JSON.stringify(params)));
    let paramsToSend = generateParams(params);

    DSM.last(
      `/s/histbets/analytics/`,
      {
        method: 'GET',
        message: 'parseDashboardData',
        body: paramsToSend,
      },
      action.data.actions,
      'parseDashboardData'
    );

    state = state.set('params', fromJS(paramsToSet));
    return state.set('isLoading', true);
  },

  //make request to get dashboard data
  //this is used by the mobile version
  getDashboardDataCompact: (state, action) => {
    let today = new Date();
    let d1 = new Date();
    d1.setTime(d1.getTime() - (today.getDay() - 1 + 28) * 24 * 60 * 60 * 1000);

    let paramsToSet = {
      dateFrom: `${toStdDate(d1)}`,
      offset: null,
      pageSize: 10000,
      q: `a:customer_ccy/a:event_date_month/a:market_type/a:system/a:competition/hv:customer_turnover/a:event/a:event_date_day/f:bookie_date_day:ge:${toStdDate(
        d1
      )}`,
    };

    let paramsToSend = {
      t: 10000,
      q: `a:customer_ccy/a:event_date_month/a:market_type/a:system/a:competition/hv:customer_turnover/a:event/a:event_date_day/f:bookie_date_day:ge:${toStdDate(
        d1
      )}`,
    };

    DSM.last(
      `/s/histbets/analytics/`,
      {
        method: 'GET',
        message: 'parseDashboardData',
        body: paramsToSend,
      },
      action.data.actions,
      'parseDashboardData'
    );

    state = state.set('params', fromJS(paramsToSet));
    let views = state.get('views');
    if (views) {
      let viewTo = '';
      views.forEach((view, vId) => {
        if (view.getIn(['query', 'q']) === state.getIn(['params', 'q'])) {
          viewTo = vId;
        }
      });
      state = state.set('selectedView', viewTo);
      if (viewTo) {
        state = state.set('setHashTo', null);
      }
    }

    return state.set('isLoading', true);
  },

  //handle the bet data
  parseDashboardData: (state, action) => {
    if (action.data.status === 'ok') {
      state = state.set('dashboardData', action.data.data.table);
      state = state.set('dashboardDataNav', action.data.data.nav);
    } else {
      //handled by base
    }
    return state.mergeDeep({
      isLoading: false,
      firstLoad: true,
    });
  },

  ////// SAVED BETS VIEWS

  //we have to init this from user preferences
  handlePrefs: (state, action) => {
    if (action.data.status === 'ok') {
      try {
        state = state.set('views', fromJS(action.data.data.history.bets.views));
        state = state.set('lastQuery', fromJS(action.data.data.history.bets.lastQuery));
      } catch (err) {
        //meh
      }
    }

    return state;
  },

  //unselect a focused view
  resetBetsView: (state, _action) => {
    return state.set('selectedView', '');
  },

  //save bet view
  saveBetsView: (state, action) => {
    const params = action.data.params;
    let query;
    if (action.data.relative) {
      query = relativeify(generateParams(params.toJS()), action.data.relative);
    } else {
      query = generateParams(params.toJS());
    }
    let toSet = { ...action.data.data, query };
    let id = action.data.data.name;
    state = state.setIn(['views', id], fromJS(toSet));
    state = state.set('selectedView', id);
    Session.set(['settings', 'history', 'bets', 'views', id], fromJS(toSet));
    return state;
  },

  //load bet view by setting the page hash to something
  //have to also insert correct dates instead of relative dates
  loadBetsView: (state, action) => {
    let view = state.getIn(['views', action.data.id], null);
    if (view) {
      let atoms = [];
      view = view.toJS();

      let query = view.query;
      if (view.relative) {
        query = derelativeify(query, view.relative);
      }

      for (let param in query) {
        atoms.push(param + '=' + query[param]);
      }

      state = state.set('setHashTo', atoms.join('&'));
      state = state.set('selectedView', action.data.id);
    }

    return state;
  },

  //delete bets view
  removeBetsView: (state, action) => {
    Session.clear(['settings', 'history', 'bets', 'views', action.data.id]);
    state = state.set('selectedView', '');
    state = state.removeIn(['views', action.data.id]);
    return state;
  },

  //close download failed warning modal
  closeDownloadFailedModal: (state, _action) => {
    return state.set('isDownloadFailed', false);
  },

  ////// LOGOUT

  //reset some state
  logout: (state, _action) => {
    state = fromJS(INITIAL_STATE);
    return state;
  },
};

export default function reducer(state = initialState, action) {
  let _action = toCamelCase(action.type);
  return functions[_action] ? functions[_action](state, action) : state;
}

export let actions = {};
for (let ct in functions) {
  actions[ct] = (data, noGA, noLog) => ({ type: ct, data, noGA, noLog });
}
